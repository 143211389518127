import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/PublicWeb/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/PublicWeb/PageNotFound"),
  },
  {
    path: "/products",
    name: "Products",
    component: () => import("../views/PublicWeb/ProductsView.vue"),
  },
  {
    path: "/product/:id/:slug",
    name: "Product",
    component: () => import("../views/PublicWeb/ProductView.vue"),
  },
  {
    path: "/foundry",
    name: "Foundry",
    component: () => import("../views/PublicWeb/FoundryView.vue"),
  },
  {
    path: "/cocomachines",
    name: "Coconut Machines",
    component: () => import("../views/PublicWeb/CocoMachines"),
  },
   {
    path: "/peppermachines",
    name: "Pepper Machines",
    component: () => import("../views/PublicWeb/PepperProducts.vue"),
  }, 
  {
    path: "/contact",
    name: "Contact Udaya",
    component: () => import("../views/PublicWeb/ContactView.vue"),
  },
   {
    path: "/clients",
    name: "Tusted Clients",
    component: () => import("../views/PublicWeb/ClientsView.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/PublicWeb/AboutView.vue"
      ),
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  document.title = "UDAYA | " + to.name;
});
export default router;
