import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@/assets/fonts/Oswald/stylesheet.css";

import "@/assets/css/styles.css";
import "@/assets/css/spinner.css";

import VueGoogleMaps from "@fawmi/vue-google-maps";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

axios.defaults.baseURL = "https://api.web.udayaindustries.lk/api/";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyC3I3qCX6FYosG8eGjFVpBb0BV0FUt0KQM",
  },
});

app.use(VueSweetalert2);

app.mount("#app");
