<template>
  <Carousel :autoplay="5000" :wrapAround="true" :transition="500">
    <Slide ref="01">
      <div class="carousel__item">
        <img class="img-fluid" style="width: 100%" src="@/assets/images/slides/01.jpg" />
      </div>
    </Slide>
    <Slide ref="02">
      <div class="carousel__item">
        <img class="img-fluid" style="width: 100%" src="@/assets/images/slides/02.jpg" />
      </div>
    </Slide>
    <Slide ref="03">
      <div class="carousel__item">
        <img class="img-fluid" style="width: 100%" src="@/assets/images/slides/03.jpg" />
      </div>
    </Slide>
    <Slide ref="04">
      <div class="carousel__item">
        <img class="img-fluid" style="width: 100%" src="@/assets/images/slides/04.jpg" />
      </div>
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "MainSlider",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      slides: ["@/assets/images/slides/01.jpg", "@/assets/images/slides/02.jpg"],
    };
  },
});
</script>

<style>
.carousel__item {
  min-height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__icon {
  color: rgb(192, 192, 192);
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 1px solid white;
}
</style>
